<template>
  <div class="wallet-statement">
    <div class="container-fluid mt--5">
      <div class="card mb-3">
        <div class="card-header">
          <h3 class="mb-0"><i class="el-icon-tickets"></i> Statement</h3>
        </div>
        <div class="card-body">
          <div class="search-area">
            <el-select
              v-model="bankholderid"
              placeholder="Select Account"
              style="width: 130px"
            >
              <el-option
                v-for="item in walletList"
                :key="item.id"
                :label="item.currency"
                :value="item.bankholderID"
              >
              </el-option>
            </el-select>

            <!-- <el-date-picker
              v-model="dateSearch"
              type="daterange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              format="yyyy-MM-dd"
            >
            </el-date-picker> -->
            <el-date-picker
              v-model="dateSearch"
              type="month"
              placeholder="Select month"
            >
            </el-date-picker>
            <el-button
              slot="append"
              type="primary"
              icon="el-icon-search"
              :loading="loading"
              :disabled="loading"
              @click="getStatement()"
              >Search</el-button
            >

            <el-button
              slot="append"
              type="danger"
              icon="el-icon-download"
              :loading="downloading"
              @click="downloadStatement('pdf')"
              
              >PDF</el-button
            >
            <el-button
              slot="append"
              type="success"
              icon="el-icon-download"
              :loading="downloading"
              @click="downloadStatement('excel')"
              
              >Excel</el-button
            >
          </div>
          <div>
            <el-table
              :data="tableData"
              row-key="uid"
              border
              style="width: 100%"
              size="mini"
              v-loading="loading"
            >
              <el-table-column
                prop="date"
                label="Date"
                header-align="center"
                align="center"
                width="160"
              ></el-table-column>
              <el-table-column
                prop="description"
                min-width="200"
                class-name="line-break"
                label="Description"
              ></el-table-column>
              <el-table-column
                prop="credit"
                label="Credit"
                header-align="center"
                align="right"
                width="100"
              ></el-table-column>
              <el-table-column
                prop="debit"
                label="Debit"
                header-align="center"
                align="right"
                width="100"
              ></el-table-column>
              <el-table-column
                prop="balance"
                label="Balance"
                header-align="center"
                align="right"
                width="100"
              ></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils";
import WalletApi from "@/api/wallet/wallet";
import { isMobile } from "@/utils/helpers";
import Auth from "@/api/auth";
export default {
  computed: {
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      tableData: [],
      dateSearch: "",
      bankholderid: "",
      export_token: "",
      walletList: [],
      query: {
        startdate: "",
        enddate: "",
      },
    };
  },
  methods: {
    getWallet() {
      WalletApi.getWalletList().then(({ result, data }) => {
        if (result) {
          this.walletList = data;
        }
      });
    },
    getStatement() {
      if (!this.bankholderid) {
        this.$swal("Message", "Please select wallet currency", "error");
        return false;
      }
      this.loading = true;
      this.getQuery();

      WalletApi.getStatement(this.bankholderid, this.query).then(
        ({ result, data }) => {
          this.loading = false;
          if (result) {
            this.tableData = data["statement"];
            this.export_token = data["export_token"];
          }
        }
      );
    },
    getQuery() {
      this.query.startdate = this.dateSearch
        ? Utils.startOfMonth(this.dateSearch)
        : Utils.startOfMonth(new Date());
      this.query.enddate = this.dateSearch
        ? Utils.endOfMonth(this.dateSearch)
        : Utils.endOfMonth(new Date());
    },

    downloadStatement(filetype) {
      if (!this.bankholderid) {
        this.$swal("Message", "Please select wallet currency", "error");
        return false;
      }
      this.getQuery();
      if (this.isMobile) {
        this.downloading = true;
        let model = JSON.parse(JSON.stringify(this.query));
        model.token = Auth.getToken();
        WalletApi.sendStatement(this.bankholderid, model).then(
          ({ result, message }) => {
            this.downloading = false;
            if (result) {
              this.$swal(
                "Message",
                "Statement infomation send to your e-mail. Please check your e-mail",
                "success"
              );
            } else {
              this.$swal("Message", message, "error");
            }
          }
        );
      } else {
        WalletApi.exportStatement(this.bankholderid, this.query, filetype);
      }
    },
  },
  mounted() {
    this.getWallet();
    if (this.$route.query && this.$route.query.bankholderid) {
      this.bankholderid = this.$route.query.bankholderid;
      this.getStatement();
    }
  },
};
</script>🇵

<style lang="scss">
.wallet-statement {
  .search-area {
    margin-bottom: 16px;
    button {
      margin-bottom: 16px;
    }
    .el-date-editor {
      max-width: 150px;
      min-width: 100px;
      margin-bottom: 16px;
      margin-right: 10px;
    }
    .el-select {
      margin-top: 12px;
      margin-right: 10px;
      margin-bottom: 16px;
    }
  }
}
</style>